import React from 'react'
import './about.css'
import { advisoryTeamInfo, managementTeamInfo } from '../../utils/utils'

const About = () => {
  return (
    <div className='about-main'>
      <div className='about-hero-main container'>
        <div className='about-hero-section'>
          <span className='title'>
            Bringing True Innovation <br /> to Patient Monitoring
          </span>
          <span className='discription'>
            App Controlled, Team Communication, and Mobility with cost and{' '}
            <br /> performance benefits never before possible
          </span>
        </div>
      </div>
      <div className='info-main'>
        <div className='info-section container'>
          <div className='info-title'>
            <span>Developed by doctors, for doctors.</span>
          </div>
          <div class='info'>
            <div class='list-section'>
              <p>
                We understand the intricacies and difficulties of patient care
                firsthand.
              </p>
              <p>
                Dr. Cristian Muniz, a specialist in Anesthesiology,
                Resuscitation and Intensive Care is the original inventor of the
                iTOF. He and many other anesthesiologists around the world have
                worked together with Nerbio to develop his concept into a truly
                groundbreaking medical device.
              </p>
              <p>
                Our senior management team of highly experienced Physicians,
                Business Executives and Engineers is committed to changing the
                future with advanced technologies that provide safe and
                easy-to-use solutions at unheard of levels of performance and
                cost effectiveness.
              </p>
              <p>
                Members of our senior management team have founded, bought, led,
                and sold multiple highly successful organizations in Health
                Care, Technology and International Finance. The entire Nerbio
                team takes immense pride in our mission to invent, manufacture
                and support best-in-class medical devices that reach new levels
                of performance, productivity and user satisfaction.
              </p>
            </div>
            <div class='image-section'>
              <img src='images/about-info-blue.png' alt='about-info' />
            </div>
          </div>
        </div>
      </div>
      <div className='management-main'>
        <div className='management-section'>
          <div className='management-title'>OUR MANAGEMENT TEAM</div>
          <div className='card-section'>
            <div class='row card-row'>
              {managementTeamInfo.map((item, index) => (
                <div class='col-lg-3 col-md-4 col-sm-12 col-xs-12'>
                  <div className='card card-main mb-3' key={index}>
                    <div class='card-body'>
                      <div className='card-img'>
                        <img src={item.imageSrc} alt={item.name} />
                      </div>
                      <div className='card-name'>{item.name}</div>
                      <div className='management-card-title'>{item.title}</div>
                      <div className='card-discription card-text'>
                        {item.discription}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className='advisory-main'>
        <div className='advisory-section'>
          <div className='advisory-title'>OUR ADVISORY TEAM</div>
          <div className='advisory-card-section'>
            <div class='row card-row'>
              {advisoryTeamInfo.map((item, index) => (
                <div class='col-lg-3 col-md-4 col-sm-12 col-xs-12'>
                  <div className='card card-main mb-3' key={index}>
                    <div class='card-body'>
                      <div className='advisory-img-hover-bg'>
                        <div className='card-img'>
                          <img src={item.imageSrc} alt={item.name} />
                        </div>
                      </div>
                      <div className='advisory-text-hover-bg'>
                        <div className='advisory-card-name'>{item.name}</div>
                        <div className='advisory-card-title'>{item.title}</div>
                        <div className='advisory-card-discription card-text'>
                          {item.discription}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
