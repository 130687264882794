import React from "react";
import "./privacypolicy.css";
const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-main" id="#policy">
      <div className="privacy-policy-hero-main container">
        <div className="privacy-policy-hero-section">
          <span className="title">Privacy Policy</span>
          <span className="discription">Last updated: August 2023</span>
        </div>
      </div>
      <div className="policy-info-section container" style={{ background: "none" }}>
        <div className="policy-info-text">
          Protecting your personal information is our priority. This Privacy
          Policy applies to Nerbio Medical Software Platforms, Inc. (“Nerbio,”
          “we,” or “us”) and governs personal information data collection and
          usage from users of our products, visitors of our website nerbio.com
          (the “Website”), users of our mobile applications (the “Apps”) or
          other services under Nerbio’s control on or offline (collectively, the
          “Services”). By using our Services, you are agreeing to this Privacy
          Policy.
        </div>
      </div>

      <div className="policy-info-section container">
        <div className="policy-info-title">WHAT IS PERSONAL INFORMATION ?</div>
        <div className="policy-info-text">
          Personal Information is information that identifies you as an
          individual or can be used to identify you. Examples includes such
          information as your name, address and contact information.
        </div>
        <div className="policy-info-title">
          Nerbio May Collect Personal Information in a Few Different Ways:
        </div>
        <div className="policy-info-points-section">
          <div className="points-row">
            <div className="point-icon">
              <img
                src="./images/policy-check-icon.png"
                alt="policy-check-icon"
              />
            </div>
            <div className="point-discription">
              <span className="policy-orange-text">
                Information That You Provide.
              </span>
              Nerbio may collect personal information that you provide when
              using our Services, and any other data that you may choose to
              submit or provide. With your permission, we may also collect data
              from another source like a device. Such information may include,
              but is not limited to, contact information (such as your name,
              e-mail address, telephone number, user ID and password), date of
              birth, pro
            </div>
          </div>
          <div className="points-row">
            <div className="point-icon">
              <img
                src="./images/policy-check-icon.png"
                alt="policy-check-icon"
              />
            </div>
            <div className="point-discription">
              <span className="policy-orange-text">
                Information from Public or Third Party Sources.
              </span>
              Nerbio may collect data from public sources including, but not
              limited to other websites that enable sharing. Personal
              information may also be available from government agencies, public
              or third party information sources, third party service providers,
              or business partners. Nerbio may also collect information from
              industry and associations
            </div>
          </div>
          <div className="points-row">
            <div className="point-icon">
              <img
                src="./images/policy-check-icon.png"
                alt="policy-check-icon"
              />
            </div>
            <div className="point-discription">
              <span className="policy-orange-text">
                Information Collected From Devices Used to Connect or Use our
                Services.
              </span>
              Nerbio may collect data through our website and apps and
              automatically from devices you use to connect to our Services.
              Please see our Cookies Policy for more information.
            </div>
          </div>
          <div className="points-row">
            <div className="point-icon">
              <img
                src="./images/policy-check-icon.png"
                alt="policy-check-icon"
              />
            </div>
            <div className="point-discription">
              <span className="policy-orange-text">
                Information When You Make a Purchase.
              </span>
              Nerbio may collect information to process, fulfill, and ship your
              order when you make a purchase from Nerbio
            </div>
          </div>
          <div className="points-row">
            <div className="point-icon">
              <img
                src="./images/policy-check-icon.png"
                alt="policy-check-icon"
              />
            </div>
            <div className="point-discription">
              <span className="policy-orange-text">
                Information Collected With Business Partners.
              </span>
              Nerbio may sometimes work with other organizations to provide you
              with our Services. Nerbio requires any such information to be
              protected.
            </div>
          </div>
        </div>
        <div className="policy-info-text">
          <span className="policy-note-text">Note: </span> If you choose to
          submit any personal information relating to other people, you
          expressly represent that you have the authority to do so and permit
          Nerbio to use the information in accordance with this Policy.
        </div>
      </div>

      <div className="policy-info-section container">
        <div className="policy-info-title capital">
          Nerbio May Collect the Following Types of Personal Information:
        </div>
        <div className="policy-info-points-section">
          <div className="points-row">
            <div className="point-icon">
              <img
                src="./images/policy-check-icon.png"
                alt="policy-check-icon"
              />
            </div>
            <div className="point-discription">
              <span className="policy-orange-text">
                Contact Information and Preferences.
              </span>
              Such as your name, email address, mailing address, and phone
              number.
            </div>
          </div>
          <div className="points-row">
            <div className="point-icon">
              <img
                src="./images/policy-check-icon.png"
                alt="policy-check-icon"
              />
            </div>
            <div className="point-discription">
              <span className="policy-orange-text">Account Information.</span>
              Such as any username, email and password that you may use in
              connection with creating an account on our website or mobile apps.
            </div>
          </div>
          <div className="points-row">
            <div className="point-icon">
              <img
                src="./images/policy-check-icon.png"
                alt="policy-check-icon"
              />
            </div>
            <div className="point-discription">
              <span className="policy-orange-text">
                Biographical and Demographical Information.
              </span>
              Such as your birth date, age, photo, gender, ethnicity, and
              marital status.
            </div>
          </div>
          <div className="points-row">
            <div className="point-icon">
              <img
                src="./images/policy-check-icon.png"
                alt="policy-check-icon"
              />
            </div>
            <div className="point-discription">
              <span className="policy-orange-text">Financial Information.</span>
              Such as information needed to complete a transaction, like credit
              card information.
            </div>
          </div>
          <div className="points-row">
            <div className="point-icon">
              <img
                src="./images/policy-check-icon.png"
                alt="policy-check-icon"
              />
            </div>
            <div className="point-discription">
              <span className="policy-orange-text">Internet Activity.</span>
              Such as your IP address, browsing history, your search history and
              information on your interaction with our Services
            </div>
          </div>
          <div className="points-row">
            <div className="point-icon">
              <img
                src="./images/policy-check-icon.png"
                alt="policy-check-icon"
              />
            </div>
            <div className="point-discription">
              <span className="policy-orange-text">
                Other Personal Information.
              </span>
              Such as information collected online or offline to better help
              Nerbio identify and offer services and information that may be of
              interest to you
            </div>
          </div>
          <div className="points-row">
            <div className="point-icon">
              <img
                src="./images/policy-check-icon.png"
                alt="policy-check-icon"
              />
            </div>
            <div className="point-discription">
              <span className="policy-orange-text">Communications.</span>
              Such as information from interactions with Nerbio
            </div>
          </div>
          <div className="points-row">
            <div className="point-icon">
              <img
                src="./images/policy-check-icon.png"
                alt="policy-check-icon"
              />
            </div>
            <div className="point-discription">
              <span className="policy-orange-text">
                Professional Credentials.
              </span>
              Such as your educational and professional history, institutional
              and government affiliations, information included on a resume,
              education and work history.
            </div>
          </div>
          <div className="points-row">
            <div className="point-icon">
              <img
                src="./images/policy-check-icon.png"
                alt="policy-check-icon"
              />
            </div>
            <div className="point-discription">
              <span className="policy-orange-text">
                Public Information Related to Your Practice.
              </span>
              Such as license information, disciplinary history, prior
              litigation and regulatory proceedings, and other due diligence
              related information.
            </div>
          </div>
          <div className="points-row">
            <div className="point-icon">
              <img
                src="./images/policy-check-icon.png"
                alt="policy-check-icon"
              />
            </div>
            <div className="point-discription">
              <span className="policy-orange-text">Location Data.</span>
              Some of Nerbio’s applications and mobile-optimized sites may have
              location-based features. To use these features, Nerbio may collect
              and use location data provided by your mobile device. When used,
              you are asked to consent to the collection and use of your mobile
              device location data for the purpose of delivering these features.
            </div>
          </div>
        </div>
      </div>

      <div className="policy-info-section container">
        <div className="policy-info-title capital">
          Collection of Aggregate Information
        </div>
        <div className="policy-info-text">
          Nerbio may also collect other non-personally identifiable information
          in an aggregate manner (“Aggregate Information”). Aggregate
          Information is information that is not personally identifiable or from
          which individual identities have been removed that Nerbio collects
          solely to assist us in maintaining and improving the quality of our
          Services provided to you. Nerbio may also collect information about
          the nature of your device from which you access our Services, such as
          your IP address, mobile or handheld identifier, browser type, access
          times and referring website in an aggregate manner that does not
          personally identify you. Nerbio uses this aggregate data solely to
          assist us in operating our Services and to maintain and improve the
          quality of our Services provided to you. Nerbio may collect this
          information passively using technologies such as server logs, cookies,
          and clear GIFs. Please be aware that Nerbio has no access to, or
          control over, any third-party passive means of data collection that
          may be linked to from our Services
        </div>
      </div>

      <div className="policy-info-section container">
        <div className="policy-info-title capital">
          How is Your Personal Information Used ?
        </div>
        <div className="policy-info-text">
          How Nerbio uses your personal information depends on how you interact
          with Nerbio and what services you use. Primarily, Nerbio uses personal
          information solely to provide our Services to you and make them
          better, to process your transactions, to communicate with you, for
          security and fraud detection, and to comply with the law. Nerbio may
          also use your personal information for other purposes with notice or
          with your consent. Generally your personal information is used to:
        </div>
        <div className="policy-info-points-section">
          <div className="points-row">
            <div className="point-icon">
              <img
                src="./images/policy-check-icon.png"
                alt="policy-check-icon"
              />
            </div>
            <div className="point-discription">
              <span className="policy-orange-text">
                Provide and Develop Our Services.
              </span>
              Nerbio collects information to provide our Services, maintain our
              website and apps, and to better understand the interests and needs
              of our customers who use our Services. Nerbio may also use your
              information for data analysis, audits, developing new products,
              and enhancing our services. Your information may also be used to
              customize your experience with Nerbio and our Services, such as
              recognizing you when you when you visit our website or remembering
              your preferences
            </div>
          </div>
          <div className="points-row">
            <div className="point-icon">
              <img
                src="./images/policy-check-icon.png"
                alt="policy-check-icon"
              />
            </div>
            <div className="point-discription">
              <span className="policy-orange-text">Process Transactions.</span>
              Nerbio collects payment information to process your transactions
              and deliver our products and services
            </div>
          </div>
          <div className="points-row">
            <div className="point-icon">
              <img
                src="./images/policy-check-icon.png"
                alt="policy-check-icon"
              />
            </div>
            <div className="point-discription">
              <span className="policy-orange-text">Communicate with You.</span>
              Nerbio uses your information to respond to your inquiries or send
              administrative communications to you regarding our Services or
              transactions. Nerbio may also notify you of special promotions or
              offers or invite you to participate in surveys about our products
              or services.
            </div>
          </div>
          <div className="points-row">
            <div className="point-icon">
              <img
                src="./images/policy-check-icon.png"
                alt="policy-check-icon"
              />
            </div>
            <div className="point-discription">
              <span className="policy-orange-text">
                Security and Fraud Detection.
              </span>
              Nerbio uses your information to monitor, detect and prevent fraud
              and improper or illegal activity and protect you, Nerbio, other
              users, and the public
            </div>
          </div>
          <div className="points-row">
            <div className="point-icon">
              <img
                src="./images/policy-check-icon.png"
                alt="policy-check-icon"
              />
            </div>
            <div className="point-discription">
              <span className="policy-orange-text">Comply with the Law.</span>
              Nerbio uses your information to comply with applicable law, our
              legal and regulatory obligations, and to defend ourselves in
              litigation, investigations, and to prosecute litigations
            </div>
          </div>
        </div>
        <div className="policy-info-text">
          Nerbio may aggregate, anonymize and or de-identify data Nerbio
          collects about customers and use it for any lawful purpose, including
          product and service development and improvement.
        </div>
      </div>

      <div className="policy-info-section container">
        <div className="policy-info-title capital">
          How is Your Personal Information Shared ?
        </div>
        <div className="policy-info-text">
          Nerbio may share your personal information with our service providers
          who act on our behalf, our partners, and at your direction. Nerbio
          does not sell personal information or transfer personal information to
          third parties to use for their own benefit.
        </div>
        <div className="policy-info-points-section">
          <div className="points-row">
            <div className="point-icon">
              <img
                src="./images/policy-check-icon.png"
                alt="policy-check-icon"
              />
            </div>
            <div className="point-discription">
              <span className="policy-orange-text">Service Providers.</span>
              Nerbio may provide your personal information to our vendors,
              contractors, business and service partners, or other third
              parties. Examples include advertisers, payment processing
              companies, customer service and support providers, email, IT
              services and SMS vendors, web hosting and development companies.
              Nerbio requires its Service Providers to keep your personal
              information confidential and to use personal information solely to
              perform functions for Nerbio.
            </div>
          </div>
          <div className="points-row">
            <div className="point-icon">
              <img
                src="./images/policy-check-icon.png"
                alt="policy-check-icon"
              />
            </div>
            <div className="point-discription">
              <span className="policy-orange-text">Collaborators.</span>
              Collaborators are partners or other organizations with which
              Nerbio jointly develops and or promotes our Services.
            </div>
          </div>
          <div className="points-row">
            <div className="point-icon">
              <img
                src="./images/policy-check-icon.png"
                alt="policy-check-icon"
              />
            </div>
            <div className="point-discription">
              <span className="policy-orange-text">Communicate with You.</span>
              Nerbio uses your information to respond to your inquiries or send
              administrative communications to you regarding our Services or
              transactions. Nerbio may also notify you of special promotions or
              offers or invite you to participate in surveys about our products
              or services.
            </div>
          </div>
          <div className="points-row">
            <div className="point-icon">
              <img
                src="./images/policy-check-icon.png"
                alt="policy-check-icon"
              />
            </div>
            <div className="point-discription">
              <span className="policy-orange-text">
                Merger, Acquisition, or Business Transfer.
              </span>
              If Nerbio sells all or part of one of its product lines or
              divisions, your information may be transferred to the buyer.
            </div>
          </div>
          <div className="points-row">
            <div className="point-icon">
              <img
                src="./images/policy-check-icon.png"
                alt="policy-check-icon"
              />
            </div>
            <div className="point-discription">
              <span className="policy-orange-text">
                Government, Regulatory and Law Enforcement Agencies.
              </span>
              Nerbio reserves the right to disclose your information to respond
              to authorized information requests from government agencies, to
              respond to valid judicial requests, to provide security and
              investigate potential fraud, or when otherwise required by
              applicable law.
            </div>
          </div>
        </div>
      </div>

      <div className="policy-info-section container">
        <div className="policy-info-title capital">
          Your Privacy Rights and Choices
        </div>
        <div className="policy-info-text">
          Nerbio deeply respects your decisions about your personal information.
          Depending on where you reside, you may have certain rights and choices
          available to you regarding our processing of your personal
          information. These may include:
        </div>
        <div
          className="policy-info-text policy-orange-text"
          style={{ color: "#ffb63b" }}
        >
          This May Includes
        </div>
        <div className="policy-info-text">
          <ol>
            <li>
              Knowing the categories and or specific pieces of personal
              information collected about you, including whether your personal
              information is sold or disclosed, and with whom your personal
              information was shared
            </li>
            <li>
              Accessing a copy of the personal information we retain about you
            </li>
            <li>Requesting deletion of your personal information</li>
            <li>Correcting your personal information</li>
          </ol>
        </div>
        <div className="policy-info-text">
          To help protect the security of your personal information, Nerbio will
          verify your identity in connection with any requests. Furthermore, if
          an authorized agent is making the request, Nerbio may require
          additional information to protect your personal information, including
          information to verify that you are authorized to make the request.
          There may be situations where Nerbio cannot grant your request, such
          as failure to verify your identity, legal or regulatory obligations to
          keep your personal information, or other reasons for denial if your
          request jeopardizes the privacy of others, or if it would be extremely
          impractical to honor. If your request is denied, Nerbio will take
          steps to inform you of the denial and the reasons for the denial. You
          may exercise any of your rights in relation to your personal
          information by contacting us using the contact details provided below.
        </div>
      </div>

      <div className="policy-info-section container">
        <div className="policy-info-title capital">Marketing Opt Out</div>
        <div className="policy-info-text">
          If you no longer wish to receive promotional marketing materials from
          Nerbio, you may opt out of receiving such materials. You may
          unsubscribe from receiving marketing emails from Nerbio by following
          the instructions included in the email. Please note that even if you
          opt out of receiving such communications, Nerbio may need to continue
          to send you certain non-marketing communications, such as information
          regarding your account or changes to our Privacy Policy or your Terms
          of Use.
        </div>
      </div>

      <div className="policy-info-section container">
        <div className="policy-info-title capital">
          How is Your Personal Information Kept Secure ?
        </div>
        <div className="policy-info-text">
          Nerbio has implemented and will maintain appropriate technical and
          organizational measures and internal controls, to protect your
          personal information from unauthorized access, use or disclosure. Any
          personal information you may provide to Nerbio is secured on computer
          servers in a controlled, secure environment, protected from
          unauthorized access. When personal information, including credit card
          information, is transmitted to other websites, it is protected through
          the use of secure encryption, such as the Secure Socket Layer (SSL)
          protocol. Unfortunately, no data transmission is guaranteed to be 100%
          secure and we therefore cannot guarantee the security of information
          you transmit to and from our Services, or through the use of our
          Website. You acknowledge that you provide personal information at your
          own risk and assume these risks.
        </div>
        <div
          className="policy-info-text policy-orange-text"
          style={{ color: "#ffb63b", marginTop: "20px" }}
        >
          Login Information Responsibility
        </div>
        <div className="policy-info-text">
          You are responsible for securing and preventing unauthorized access to
          your user name and password that you use to access our Services. You
          agree not to disclose your account login information to any
          third-party and you are responsible for any activity using your
          account, whether or not you authorized that activity. You must
          immediately notify us of any unauthorized use of your user account.
        </div>
        <div
          className="policy-info-text policy-orange-text"
          style={{ color: "#ffb63b", marginTop: "20px" }}
        >
          Third Party Sites.
        </div>
        <div className="policy-info-text">
          Our Services may contain links to other websites, products, or
          services operation by third-parties. Please be aware that this Policy
          does not apply to your activities or any information you disclose
          while using those third-party websites, products or services. We are
          not responsible for the privacy practices of any third-parties. We
          advise you to review the privacy policies applicable to any website or
          service you visit other than the Services we provide.
        </div>
        <div
          className="policy-info-text policy-orange-text"
          style={{ color: "#ffb63b", marginTop: "20px" }}
        >
          Children’s Information.
        </div>
        <div className="policy-info-text">
          Our Services are not intended for children under the age of thirteen.
          We do not knowingly collect personally identifiable information from
          children under the age of thirteen years old, or allow such children
          to order our products, communicate with us, or use any of our online
          services. If you are under the age of thirteen, you must ask your
          parent or guardian for permission to use our website and its services.
          If you are a parent or guardian and become aware that your child has
          provided us with information, please contact us using the method below
          and we will work with you to address this issue
        </div>
      </div>

      <div className="policy-info-section container">
        <div className="policy-info-title capital">
          Cookies and Other Technologies
        </div>
        <div className="policy-info-text">
          Nerbio’s Services and Website may use cookies (a small text file sent
          by your computer each time you visit our sites, unique to your Nerbio
          account and browser) and other technologies. Nerbio uses the
          information gathered from cookies and other technologies solely to
          better understand, customize and improve user experience with Nerbio’s
          Website and Services. This information can make your use of our
          Services easier and more meaningful by allowing Nerbio and our service
          providers to provide you with better service, customized based on your
          preferences, compile statistics, analyze trends and otherwise
          administer and improve our products and services. Nerbio may also
          associate this information with any personal information collected.
          Your web browser can be set to allow you to control whether you will
          accept cookies, reject cookies, or to notify you each time a cookie is
          sent to your browser. Your browser may also have a Do Not Track
          feature that allows you to tell a website not to track you. Nerbio
          honors the Do Not Track signal. However, please note that if you
          reject cookies from Nerbio, some Website functionality may be lost
        </div>
        <div
          className="policy-info-text policy-orange-text"
          style={{ color: "#ffb63b" }}
        >
          Types of Information Collected:
        </div>
        <div className="policy-info-text">
          <ol style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <li>
              <span className="policy-orange-text"> Log Data :</span> When you
              use Nerbio’s Website and apps, our servers automatically record
              information, including information that your browser sends
              whenever you visit a website or app or your mobile app sends when
              using it. This log data may include your IP address, the address
              of the webpages you visited with Nerbio’s features, browser type
              and settings, the date and time of your request, how you used
              website and cookie data.
            </li>
            <li>
              <span className="policy-orange-text"> Cookie Data :</span> When
              you use Nerbio’s Website and apps, Nerbio may use cookies or
              similar technologies to record log data.
            </li>
            <li>
              <span className="policy-orange-text"> Linked Accounts :</span> If
              you choose to link your account with another service provider to
              Nerbio, this allows us to obtain information from those accounts.
            </li>
            <li>
              <span className="policy-orange-text"> Device Information :</span>{" "}
              Nerbio may also collect information about the device you are using
              to access our Services, including what type of device it is, what
              operating system it is using, device settings, unique device
              identifiers and crash data.
            </li>
          </ol>
        </div>
      </div>

      <div className="policy-info-section container">
        <div className="policy-info-title capital">International Users</div>
        <div className="policy-info-text">
          By providing your Personal Information to us through use of our
          Services, you agree to transfer, store and process your data in the
          United States, and any other countries or regions we may transfer your
          data to in connection with fulfilling your requests and operating the
          Services. Please be aware that each region can have its own privacy
          and data security laws, which may be less stringent than those of your
          own region.
        </div>
      </div>

      <div className="policy-info-section container">
        <div className="policy-info-title capital">
          How can I contact Nerbio?
        </div>
        <div className="policycontact-section">
          <div className="policy-contact-left">
            <div>If you have questions about your personal information,</div>
            <div>please contact us by e-mailing us at</div>
            <div style={{ color: "#0386FF" }}>compliance@nerbio.com.</div>
          </div>
          <div className="policy-contact-right">
            <div>
              Alternatively, you may send a letter to the following address:
            </div>
            <div className="policy-orange-text">
              Nerbio Medical Software Platforms, Inc. 1900 Camden Ave San Jose,
              CA 95124
            </div>
          </div>
        </div>
        <div className="policy-info-text">
          In all communications with Nerbio, please include your email address
          used for registration, the specific product or service your provided
          personal information to, and a detailed explanation of your request.
          We will try to respond to all reasonable requests within a timely
          manner.
        </div>
      </div>

      <div className="policy-info-section container">
        <div className="policy-info-title capital">Updates</div>
        <div className="policy-info-text">
          Nerbio reserves the right to amend this Privacy Policy at any time.
          Updates to this Privacy Policy will be posted on this page. We
          encourage you to periodically review this Privacy Policy for changes
          and to learn how Nerbio is protecting your information.
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
