export const managementTeamInfo = [
  {
    imageSrc: 'images/management-member-1.png',
    name: 'Henry Sullivant MD',
    title: 'Chief Physician Executive',
    discription:
      'Henry brings decades of experience as an obstetrics surgeon and healthcare system executive, along with a passion for delivering outstanding patient care to the Nerbio Executive Team. From 2013 to 2021, Dr. Sullivant was the Vice President and Chief Medical Officer of Baptist Memorial HealthCare, a health system with 23 acute care facilities in 3 states.'
  },
  {
    imageSrc: 'images/management-member-2.png',
    name: 'Ken Leonard',
    title: 'Chief Executive Officer',
    discription:
      "Ken founded 17 tech companies, including the first web host to reach over a million customers, the McAfee Secure service, and China's first international e-commerce platform. He received Letters of Commendation from the mayors of San Francisco, Shanghai and Shenzhen. Investors in companies he founded include President George H. Bush, Citi Group, HSBC and Softbank."
  },
  {
    imageSrc: 'images/management-member-3.png',
    name: 'Christian Sapunar',
    title: 'Chief Marketing Officer',
    discription:
      'Christian is a business engineer with postgraduate studies in economics at Georgetown University. He has headed international negotiations in the US, China, and Europe leading business developments in the mobile industry and the technology sector throughout Latin America for Microsoft and Telefonica, among other corporations for over 20 years.'
  },
  {
    imageSrc: 'images/management-member-4.png',
    name: 'Vincent Titolo',
    title: 'Chief Financial Officer',
    discription:
      'Vincent has extensive experience in both the corporate management and investment sectors, including co-founding five startups: managing a VC fund as well as Sr. VP and CFO positions in three NYSE tech Fortune 500 companies. He has led several LBO and Private Placements and several IPOs on NASDAQ & NYSE exchanges. Vincent has also served on corporate boards in the US, France, Germany, and the UK.'
  },
  {
    imageSrc: 'images/management-member-5.png',
    name: 'Ken Carter',
    title: 'Chief Technical Officer',
    discription:
      "Ken brings over 30 years of outstanding technical innovation to Nerbio. With a strong background in physics and electronics he has pioneered breakthroughs in cryogenics, optics, and micro-processor systems including the world's first computerized special effects in the TV broadcast industry for Ampex, the world's leading flat-bed scanner and digital tablet technology for UMAX, as well as patented technologies for other leading companies, including Sony."
  },
  {
    imageSrc: 'images/management-member-6.png',
    name: 'Pradeep Sehgal',
    title: 'VP Operations',
    discription:
      'Lt. Col. Pradeep Sehgal served in the Indian army for 22 years, stepping out to pursue his interests in the technology domain. He ventured into the gaming and technology industry in 2006 and has overseen the development of innovative products in Virtual Reality that enhance the quality of life of patients with chronic pain and phantom limb pain.'
  },
  {
    imageSrc: 'images/management-member-7.png',
    name: 'Lloyd Leonard Esq',
    title: 'Compliance Officer, Counsel',
    discription:
      'As a practicing attorney, Lloyd brings over 13 years’ experience in international corporate law, and medical device compliance management. Lloyd also brings skills in implementing and maintaining FDA compliant Quality Management Systems and ISO Certifications, as well as knowledge of international medical device regulations.'
  },
  {
    imageSrc: 'images/management-member-8.png',
    name: 'Shane Lobo',
    title: 'Director, Hardware Engineering',
    discription:
      'MS in Electronic’s  from GOA university, Shane brings 13 years of digital and analog hardware engineering experience to Nerbio. Shane is passionate about ensuring the highest quality state-of-the-art engineering goes into Nerbio’s medical devices.'
  },
  {
    imageSrc: 'images/management-member-9.png',
    name: 'Swapnil Pai',
    title: 'Quality Control Administrator',
    discription:
      'Swapnil brings over 14 years of experience, as well as an Executive MBA and MS in Information Technology to Nerbio’s management team. He has over 4 years’ experience as a software engineer and 10 years as an Operations and Quality Compliance Manager in medical device manufacturing.'
  },
  {
    imageSrc: 'images/management-member-10.png',
    name: 'Garland Wong',
    title: 'Chairman, Board of Directors',
    discription:
      'Garland is a global serial entrepreneur who has co-founded companies on every major continent. His third company CommerceWAVE was involved in building the first credit card payment system on the internet, where he returned 30x to his investors. He is also a limited partner in Menlo Ventures and Global Internet Ventures and the CEO of Pago46, a fast growing multi-national Fin-Tech company.'
  }
]

export const advisoryTeamInfo = [
  {
    imageSrc: 'images/advicory2.png',
    name: 'Sowmya Viswanathan',
    title: 'MD MHCM, MBA, FACP',
    discription:
      'Sowmya is Vice President and Chief Medical Officer of the BayCare Group, a leading health system comprised of 15 hospitals. Sowmya completed her Residency at Boston University and her master’s in health care management (MHCM) at Harvard. At BayCare she is responsible for ongoing strategy, engaging clinical leaders to ensure delivery of high quality and value-driven care as well as optimizing financial and operational performance.'
  },
  {
    imageSrc: 'images/advicory1.png',
    name: 'Harold Arkoff',
    title: 'MD',
    discription:
      'Harold is an internationally recognized anesthesiologist with multiple publications and awards. With over 30 years of experience in the OR in academics, major health centers, community and ambulatory surgery centers he is an expert on utilization and management of operating room resources. He is also the CEO and cofounder of OneSource Solutions International (OSSI), a global health IT company delivering edge computing and bedside AI for healthcare professionals.'
  },
  {
    imageSrc: 'images/advicory3.png',
    name: 'Cristian Muniz',
    title: 'MD',
    discription:
      'Dr. Muniz is the original inventor of the iTOF and continues to work with Nerbio to develop his concept into a world-class medical device. He is a specialist in Anesthesiology, Resuscitation and Intensive Medicine. He co-invented the Pneumask: Modified Full-Face Snorkel Masks as Reusable Personal Protective Equipment for Hospital Personnel during the COVID-19 outbreak. He also holds a patent for the Oral mouthpiece for endoscopic procedures.'
  },
  {
    imageSrc: 'images/advicory4.png',
    name: 'Sebastian Monsalves',
    title: 'MD',
    discription:
      "Sebastian is board-certified in Anesthesiology and the President of the Airway Committee in Chile. He has been working as a clinical anesthesiologist and professor of the specialty since 2011. He leads Chile's bi-annual Latin American Airway Training organization, including lectures and training workshops held nationwide. He is also a lecturer in the airway training program emphasizing diagnostic vigilance evaluation, airway ultrasound (POCUS), and training in intubation with flexible bronchoscopy."
  },
  {
    imageSrc: 'images/advicory5.png',
    name: 'Fred Geisler',
    title: 'MD, PH.D',
    discription:
      'Fred is an internationally renowned expert in spinal care and a pioneer of new, innovative procedures. He is one of the nation’s leading authorities on spinal cord injuries and degenerative diseases of the spine. He led new spinal treatments and technologies and was the first physician in the US to adopt anterior cervical plating, as well as total lumbar disc replacement. Fred is also an active researcher, lecturer, and author. His interests include relieving post-operative pain, spinal stabilization and fusion techniques, and minimally invasive spinal surgery.'
  }
]

export const itofCardsInfo = [
  {
    discription:
      'Our advanced technology combines ease of use and wireless mobility with accurate NMB measurements to bring a new level of confidence to your anesthesiology practice.'
  },
  {
    discription:
      'The easy-to-use iTOF app works on any phone or tablet. It adds a layer of mobility and intelligence that greatly increases efficiency and accuracy.'
  },
  {
    discription:
      'It even talks to you. Use earbuds to listen to measurements at preset intervals and automatic alerts from anywhere in the OR or ICU.'
  },
  {
    discription:
      'Fast and easy patient setup with fully automatic calibration and instant fault detection.'
  },
  {
    discription:
      'Wireless monitoring allows unprecedented freedom of movement to improve efficiency. Patients can be continuously monitored from anywhere in the OR, or even outside the isolation ICU.'
  }
]
