import React from 'react'
import './footer.css'
import { useNavigate } from 'react-router-dom'

const Footer = () => {
  const navigate = useNavigate()
  return (
    <div className='footer-wrapper'>
        <div className='footer-text'>© 2023 All rights reserved. Terms of use | <a href="privacy-policy"><span  className='privacy-policy' onClick={() => navigate('/privacy-policy')}>Privacy Policy</span></a></div>
    </div>
  )
}

export default Footer