import React from "react";
import "./header.css";
import { Link } from "react-router-dom";

const NavBarItems = [
  {
    name: "iTOF Device",
    path: "/itof-device",
  },
  {
    name: "iTOF App",
    path: "iTOF App",
  },
  {
    name: "Blog",
    path: "Blog",
  },
  {
    name: "Demo",
    path: "Demo",
  },
  {
    name: "User Guide",
    path: "User Guide",
  },
  {
    name: "About Us",
    path: "/about",
  },
];

const Header = ({ activeRoute, setActiveroute }) => {

  const handleNavigate = (path) => {
    setActiveroute(path);
    var element = document.getElementById("navbarNav");
    element.classList.remove("show");
  };

  return (
    <nav class="navbar navbar-expand-lg">
      <div class="container">
        <Link to={"/"} onClick={() => setActiveroute("/")}>
          <div class="navbar-brand">
            <img src="./images/logo.png" alt="logo" />
          </div>
        </Link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav">
            {NavBarItems.map((item, index) => (
              <Link to={item.path}>
                <li
                  class="nav-item cool-link"
                  key={index}
                  onClick={() => handleNavigate(item.path)}
                >
                  <div
                    class={`nav-link ${activeRoute === item.path && "active"}`}
                  >
                    {item.name}
                  </div>
                </li>
              </Link>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
