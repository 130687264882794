import { Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Dashboard from "./pages/dashboard/Dashboard";
import About from "./pages/about/About";
import { useEffect, useState } from "react";
import ItofDevice from "./pages/itofdevice/ItofDevice";
import PrivacyPolicy from "./pages/privacypolicy/PrivacyPolicy";

function App() {
  const [activeRoute, setActiveroute] = useState("");

  useEffect(() => {
    const route = window.location.pathname;
    setActiveroute(route);
  }, [activeRoute]);

  return (
    <div className="App">
      <Header activeRoute={activeRoute} setActiveroute={setActiveroute} />
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/about" element={<About />} />
        <Route path="/itof-device" element={<ItofDevice />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
